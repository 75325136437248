/** Get Coupons */
export const GET_COUPONS = "GET_COUPONS";
export const GET_COUPONS_SUCCESS = "GET_COUPONS_SUCCESS";
export const GET_COUPONS_FAIL = "GET_COUPONS_FAIL";

export const GET_COUPONS_SERVERSIDE = "GET_SERVERSIDE_COUPONS";
export const GET_COUPONS_SERVERSIDE_SUCCESS = "GET_COUPONS_SERVERSIDE_SUCCESS";
export const GET_COUPONS_SERVERSIDE_FAIL = "GET_COUPONS_SERVERSIDE_FAIL";



export const GET_COUPONS_REPORT = "GET_COUPONS_REPORT";
export const GET_COUPONS_REPORT_SUCCESS = "GET_COUPONS_REPORT_SUCCESS";
export const GET_COUPONS_REPORT_FAIL = "GET_COUPONS_REPORT_FAIL";

export const GET_COUPON_DETAIL = "GET_COUPON_DETAIL";
export const GET_COUPON_DETAIL_SUCCESS = "GET_COUPON_DETAIL_SUCCESS";
export const GET_COUPON_DETAIL_FAIL = "GET_COUPON_DETAIL_FAIL";
  
export const ADD_COUPON = "ADD_COUPON";
export const ADD_COUPON_SUCCESS = "ADD_COUPON_SUCCESS";
export const ADD_COUPON_FAIL = "ADD_COUPON_FAIL";

export const UPDATE_COUPON = "UPDATE_COUPON";
export const UPDATE_COUPON_SUCCESS = "UPDATE_COUPON_SUCCESS";
export const UPDATE_COUPON_FAIL = "UPDATE_COUPON_FAIL";

 
export const DELETE_COUPON = "DELETE_COUPON";
export const DELETE_COUPON_SUCCESS = "DELETE_COUPON_SUCCESS";
export const DELETE_COUPON_FAIL = "DELETE_COUPON_FAIL";

export const RESET_COUPON = "RESET_COUPON";