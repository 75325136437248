import {
  GET_COUPONS,
  GET_COUPONS_SUCCESS,
  GET_COUPONS_FAIL,
  GET_COUPON_DETAIL,
  GET_COUPON_DETAIL_SUCCESS,
  GET_COUPON_DETAIL_FAIL,
  ADD_COUPON,
  ADD_COUPON_SUCCESS,
  ADD_COUPON_FAIL,
  UPDATE_COUPON,
  UPDATE_COUPON_SUCCESS,
  UPDATE_COUPON_FAIL,
  DELETE_COUPON,
  DELETE_COUPON_SUCCESS,
  DELETE_COUPON_FAIL,
  RESET_COUPON,
  GET_COUPONS_REPORT_SUCCESS,
  GET_COUPONS_REPORT_FAIL,
  GET_COUPONS_SERVERSIDE_SUCCESS,
  GET_COUPONS_SERVERSIDE_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  data: [],
  error: null,
  opt: null, 
}

const Coupons = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_COUPONS_SUCCESS: 
      return {
        ...state,
        data: action.payload.data,
      }
    case GET_COUPONS_SERVERSIDE_SUCCESS: 
      return {
        ...state,
        data: action.payload.data,
        opt:{
          pageCount:action.payload.pageCount,
          productCount:action.payload.productCount,
          productTotal:action.payload.productTotal,
          status:action.payload.status, 
          extra:action.payload.extra
        }
      }
    case GET_COUPONS_REPORT_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
      }
    case ADD_COUPON_SUCCESS:
      return {
        ...state,
        data: [action.payload.data, ...state.data],
      }
    case UPDATE_COUPON_SUCCESS:
      return {
        ...state,
        data: state.data.map(x =>
          x._id.toString() === action.payload.data._id.toString()
            ? { x, ...action.payload.data }
            : x
        ),
      }
    case DELETE_COUPON_SUCCESS:
      return {
        ...state,
        data: state.data.filter(
          branch => branch._id.toString() !== action.payload.data.toString()
        ),
      }

    //FAILS
    case GET_COUPONS_FAIL:
    case GET_COUPONS_SERVERSIDE_FAIL:
    case GET_COUPONS_REPORT_FAIL:
    case DELETE_COUPON_FAIL:
    case ADD_COUPON_FAIL:
    case UPDATE_COUPON_FAIL:
      return {
        ...state,
        error: action.payload,
        opt: null,
      }

    case RESET_COUPON:
      return {
        ...state,
        error: null,
        opt: null,
      }
    default:
      return state
  }
}

export default Coupons
