import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
  Table,
  Button,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap"
import InputMask from "react-input-mask"
import MetaTags from "react-meta-tags"

import { useSelector, useDispatch } from "react-redux"

import { withRouter } from "react-router-dom"
//i18n
import { withTranslation } from "react-i18next"
import { FormatTLBasic } from "./../../../components/turkishMoneyFormat"

import ReactDragListView from "react-drag-listview/lib/index.js"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import "./datatables.scss"
import "./custom-product.scss"

import {
  getCoupons as onGetCoupons,
  getCouponsServerSide as onGetCouponsServerSide,
  addCoupon as onAddNewCoupon,
  updateCoupon as onUpdateCoupon,
  resetCoupon as onResetCoupon,
} from "store/actions"
import { isEmpty, map } from "lodash"
import moment from "moment"

import * as Yup from "yup"
import { useFormik } from "formik"

import SweetAlert from "react-bootstrap-sweetalert"
import _logo from "./../../../assets/images/Kutup.png"

import header_img_1 from "./../../../assets/images/coupons/kutup-qrcode-3.png"
import header_img_2 from "./../../../assets/images/coupons/Logo_kutup_QR3.jpg"
import classNames from "classnames"

const Dashboard_v2 = props => {
  const stopProject = process.env.REACT_APP_STOP_PROJECT === 'true';
  const dispatch = useDispatch()
  const { error, data, opt } = useSelector(state => ({
    error: state.Coupon?.error,
    data: state.Coupon?.data || [],
    opt: state.Coupon?.opt,
  }))

  const [detailModal, setDetailModal] = useState(false)
  const [voidModal, setVoidModal] = useState(false)
  const [previewModal, setPreviewModal] = useState(false)
  const [selectedRow, setSelectedRow] = useState(null)
  const [order, setOrder] = useState(null)

  const [list, setList] = useState([])
  const [modalTableOptions, setModalTableOptions] = useState(false)
  const [loading, setLoading] = useState(true)
  const [firstLoading, setFirstLoading] = useState(false)

  const [tableOptions, setTableOptions] = useState()
  const [tableSettings, setTableSettings] = useState()

  const couponMaxItems = 150

  const defaultTableSettings = {
    version: "1.3.6",
    settings: [
      {
        title: "Id",
        key: "counponIdMax",
        isType: "text",
        visible: true,
        hiddenOption: true,
        classNames: "td-id",
      },
      {
        title: "Fatura No",
        key: "faturaNo",
        isType: "text",
        visible: true,
        hiddenOption: false,
        classNames: "td-faturaNo",
      },
      {
        title: "Adı",
        key: "firstName",
        isType: "text",
        visible: true,
        hiddenOption: false,
        classNames: "td-firstName",
      },
      {
        title: "Soyadı",
        key: "lastName",
        isType: "text",
        visible: true,
        hiddenOption: false,
        classNames: "td-lastName",
      },
      {
        title: "Doğum Tarihi",
        key: "dateOfBirth",
        isType: "date",
        visible: true,
        hiddenOption: false,
        classNames: "td-dateOfBirth",
      },
      {
        title: "Telefon",
        key: "phone",
        isType: "text",
        visible: true,
        hiddenOption: false,
        classNames: "td-phone",
      },

      {
        title: "İl",
        key: "country",
        isType: "text",
        visible: true,
        hiddenOption: false,
        classNames: "td-country",
      },

      {
        title: "İlçe",
        key: "town",
        isType: "text",
        visible: true,
        hiddenOption: false,
        classNames: "td-town",
      },

      {
        title: "Adres",
        key: "address",
        isType: "longtext",
        visible: true,
        hiddenOption: false,
        classNames: "td-address",
      },
      {
        title: "İşlem Tarihi",
        key: "createdAt",
        isType: "datetime",
        visible: true,
        hiddenOption: false,
        classNames: "td-createdAt",
      },
      {
        title: "Durum",
        key: "status",
        isType: "status",
        visible: true,
        hiddenOption: false,
        classNames: "td-status",
      },
    ],
  }

  const defaultTableOptions = {
    version: "1.3.6",
    pageCount: 0,
    productCount: 0,
    productTotal: 0,
    page: 1,
    limit: 50,
    selectedPage: 1,
    paging: [],
    orderBy: "counponIdMax",
    ascDesc: -1,
  }

  useEffect(() => {
    if (isEmpty(data)) {
      let tSetting = tableSettings || defaultTableSettings

      let selectList = tSetting?.settings
        .filter(x => x.visible == true)
        .map(e => e.key)
        .join(" ")

      if (!isEmpty(tableOptions)) {
        dispatch(
          onGetCouponsServerSide(
            tableOptions.selectedPage,
            tableOptions.limit,
            selectList,
            tableOptions.orderBy,
            tableOptions.ascDesc
          )
        )
      }
    }
  }, [])
  useEffect(() => {
    if (!firstLoading) {
      setFirstLoading(true)
      let defaultStt = localStorage.getItem("productTableSettings")
      let defaultOpt = localStorage.getItem("productTableOptions")

      //Table Options Control
      if (defaultOpt) {
        let dOps = JSON.parse(defaultOpt)
        if (dOps.version === defaultTableOptions.version) {
          setTableOptions(dOps)
        } else {
          setTableOptions(defaultTableOptions)
        }
      } else {
        setTableOptions(defaultTableOptions)
      }

      //Table Settings Control
      if (defaultStt) {
        let dStt = JSON.parse(defaultStt)
        if (dStt.version === defaultTableSettings.version) {
          setTableSettings(dStt)
        } else {
          localStorage.setItem(
            "productTableSettings",
            JSON.stringify(defaultTableSettings)
          )
          setTableSettings(defaultTableSettings)

          localStorage.removeItem("authUser")
          window.location.reload()
        }
      } else {
        localStorage.setItem(
          "productTableSettings",
          JSON.stringify(defaultTableSettings)
        )
        localStorage.removeItem("authUser")
        window.location.reload()
      }
    }

    if (
      !isEmpty(tableOptions) &&
      !isEmpty(tableSettings) &&
      loading
      //|| isEmpty(list)
    ) {
      let selectList = tableSettings?.settings
        .filter(x => x.visible == true)
        .map(e => e.key)
        .join(" ")

      dispatch(
        onGetCouponsServerSide(
          tableOptions.selectedPage,
          tableOptions.limit,
          selectList,
          tableOptions.orderBy,
          tableOptions.ascDesc
        )
      )
    }
  }, [list, loading, tableSettings, tableOptions])

  useEffect(() => {
    if (!isEmpty(data)) {
      setLoading(false)
      setList(data)
      console.log("is here", opt, tableOptions)
      if (!isEmpty(opt)) {
        let pageCount = opt.pageCount
        let paging = [1]
        console.log("pageCount", pageCount)
        if (pageCount > 1 && pageCount < 8) {
          for (let i = 2; i <= pageCount; i++) {
            paging.push(i)
          }
        } else if (
          pageCount > 1 &&
          pageCount >= 8 &&
          (opt.page == 1 || opt.page == pageCount)
        ) {
          let center = pageCount / 2

          console.log("center-1:", center)
          paging.push(center - 1)
          paging.push(center)
          paging.push(center + 1)
          paging.push(pageCount)
        } else if (pageCount > 1 && pageCount >= 8) {
          let center = tableOptions?.page || 0

          console.log("center-2:", tableOptions?.page, center)
          if (
            !paging.includes(center - 1) &&
            center - 1 > 0 &&
            center - 1 < pageCount
          )
            paging.push(center - 1)
          if (!paging.includes(center) && center > 0 && center < pageCount)
            paging.push(center)
          if (
            !paging.includes(center + 1) &&
            center + 1 > 0 &&
            center + 1 < pageCount
          )
            paging.push(center + 1)
          if (!paging.includes(pageCount)) paging.push(pageCount)
        }
        console.log("paging", paging)
        setTableOptions({
          ...tableOptions,
          pageCount: opt.pageCount,
          productCount: opt.productCount,
          productTotal: opt.productTotal,
          paging: paging,
        })
      }
    }
  }, [data])

  // useEffect(() => {
  //   if (!isEmpty(opt) && !isEmpty(tableOptions) && !isEmpty(tableOptions)) {
  //     let pageCount = response.data.pageCount
  //     let paging = [1]
  //     if (pageCount > 1 && pageCount < 8) {
  //       for (let i = 2; i <= pageCount; i++) {
  //         paging.push(i)
  //       }
  //     } else if (
  //       pageCount > 1 &&
  //       pageCount >= 8 &&
  //       (listParameters.page == 0 || listParameters.page == pageCount - 1)
  //     ) {
  //       let center = pageCount / 2
  //       paging.push(center - 1)
  //       paging.push(center)
  //       paging.push(center + 1)
  //       paging.push(pageCount)
  //     } else if (pageCount > 1 && pageCount >= 8) {
  //       let center = listParameters.page + 1
  //       if (!paging.includes(center - 1)) paging.push(center - 1)
  //       if (!paging.includes(center)) paging.push(center)
  //       if (!paging.includes(center + 1)) paging.push(center + 1)
  //       if (!paging.includes(pageCount)) paging.push(pageCount)
  //     }

  //     setTableOptions({
  //       ...tableOptions,
  //       pageCount: opt.pageCount,
  //       productCount: opt.productCount,
  //       productTotal: opt.productTotal,
  //       paging: paging,
  //     })
  //   }
  // }, [opt])

  const handleDate = date => {
    if (isEmpty(date)) {
      return "--"
    }
    return moment(date).format("DD.MM.YYYY")
  }
  const handleDateTime = date => {
    if (isEmpty(date)) {
      return "--"
    }
    return moment(date).add(3, "hours").format("DD.MM.YYYY HH:mm")
  }

  const toggleDetail = () => {
    setDetailModal(!detailModal)
  }

  const togglePreview = () => {
    setPreviewModal(!previewModal)
  }

  const toggleVoid = () => {
    setVoidModal(!voidModal)
  }

  const toggleTableOptions = () => {
    setModalTableOptions(!modalTableOptions)
  }

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      faturaNo: (order && order.faturaNo) || "",
      firstName: (order && order.firstName) || "",
      lastName: (order && order.lastName) || "",
      amount: (order && order.amount) || "",
      phone: (order && order.phone) || "",
      country: (order && order.country) || "",
      town: (order && order.town) || "",
      address: (order && order.address) || "",
      dateOfBirth: (order && order.dateOfBirth) || "",
    },
    validationSchema: Yup.object({
      faturaNo: Yup.string().required("* Zorunlu"),
      firstName: Yup.string().required("* Zorunlu"),
      lastName: Yup.string().required("* Zorunlu"),
      amount: Yup.string().required("* Zorunlu"),
      phone: Yup.string().required("* Zorunlu"),
      country: Yup.string().required("* Zorunlu"),
      town: Yup.string().required("* Zorunlu"),
      address: Yup.string().required("* Zorunlu"),
      dateOfBirth: Yup.string().required("* Zorunlu"),
    }),
    onSubmit: values => {
      const newOrder = {
        //id: Math.floor(Math.random() * (30 - 20)) + 20,
        faturaNo: values["faturaNo"],
        firstName: values["firstName"],
        lastName: values["lastName"],
        amount: values["amount"],
        phone: values["phone"],
        country: values["country"],
        town: values["town"],
        address: values["address"],
        dateOfBirth: values["dateOfBirth"],
      }
      // save new order
      dispatch(onAddNewCoupon(newOrder))
      validation.resetForm()
      toggleDetail()
    },
  })

  // validation
  const validation2 = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      description: (order && order.description) || "",
    },
    validationSchema: Yup.object({
      description: Yup.string().required("* Zorunlu"),
    }),
    onSubmit: values => {
      const updateOrder = {
        id: selectedRow._id,
        description: values["description"],
        status: "void",
      }
      // save new order
      dispatch(onUpdateCoupon(updateOrder))
      validation.resetForm()
      toggleVoid()
      togglePreview()
    },
  })

  const loadingView = () => {
    return loading ? (
      <div className="loading">
        <div className="spinner-border text-light" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    ) : null
  }
  const handleLimitSubmit = e => {
    if (e.key === "Enter") {
      localStorage.setItem("productTableOptions", JSON.stringify(tableOptions))
      refresh()
    }
  }

  const changeInputTableSettings = (i, val, inputName) => {
    const values = { ...tableSettings }
    values.settings[i][inputName] = val
    setTableSettings(values)
    localStorage.setItem("productTableSettings", JSON.stringify(values))
    refresh()
  }

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      let offset = 1 //id option hidden
      const modifiedData = { ...tableSettings }

      const item = modifiedData.settings.splice(fromIndex + offset, 1)[0]
      modifiedData.settings.splice(toIndex + offset, 0, item)
      setTableSettings(modifiedData)
      localStorage.setItem("productTableSettings", JSON.stringify(modifiedData))
      refresh()
    },
    nodeSelector: "tr",
    handleSelector: "tr",
  }

  const refresh = () => {
    setLoading(true)
  }

  const getListManuel = _opt => {
    console.log(_opt)
    let selectList = tableSettings?.settings
      .filter(x => x.visible == true)
      .map(e => e.key)
      .join(" ")

    dispatch(
      onGetCouponsServerSide(
        _opt.page,
        _opt.limit,
        selectList,
        _opt.orderBy,
        _opt.ascDesc
      )
    )
  }

  const getInput = (item, index, hIndex) => {
    let _h = tableSettings.settings[hIndex]
    if (hIndex === 0)
      return <span>{list[index][_h.key].toString().padStart(7, "0")}</span>
    else if (_h.isType == "text") return <span>{list[index][_h.key]}</span>
    else if (_h.isType == "longtext")
      return isEmpty(list[index][_h.key])
        ? ""
        : list[index][_h.key].length > 15
        ? list[index][_h.key].substring(0, 15) + "..."
        : list[index][_h.key]
    else if (_h.isType == "number")
      return <span className="float-right">{list[index][_h.key]}</span>
    else if (_h.isType == "price")
      return (
        <span className="float-right">
          {FormatTLBasic(list[index][_h.key])}
        </span>
      )
    else if (_h.isType == "date")
      return <span>{handleDate(list[index][_h.key])}</span>
    else if (_h.isType == "datetime")
      return <span>{handleDateTime(list[index][_h.key])}</span>
    else if (_h.isType == "status")
      return list[index][_h.key] == "ok" ? (
        <span className="text-success">
          <i className="bx bx-comment-check"></i> Onaylı
        </span>
      ) : (
        <span className="text-danger">
          <i className="bx bx-comment-check"></i> İptal
        </span>
      )
    else return <span>{list[index][_h.key]}</span>
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Kuponlar</title>
        </MetaTags>
        <div className="container-fluid">
          <Breadcrumbs
            title="Tablolar"
            breadcrumbItem="Kuponlar"
            addBtn={!stopProject}
            addBtnCallBack={() => {
              setOrder({
                faturaNo: "",
                firstName: "",
                lastName: "",
                phone: "",
                amount: "",
                dateOfBirth: "",
                country: "",
                town: "",
                address: "",
              })
              toggleDetail()
            }}
          />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  {/* <CardTitle className="h4">Rapor</CardTitle> */}
                  {error && error ? (
                    <Alert color="danger">{error}</Alert>
                  ) : null}
                  {stopProject && stopProject ? (
                    <Alert color="danger">Hizmet Durduruldu</Alert>
                  ) : null}

                  {/* TABLE */}
                  {!stopProject && (
                    <div className="table-responsive relative">
                      {loadingView()}
                      <div className="productSettings">
                        <div className="d-flex">
                          <p></p>
                        </div>
                        <div className="d-flex float-end">
                          <div>
                            <input
                              type="number"
                              step={"1"}
                              min={"1"}
                              className={"form-control sizeOfPage"}
                              value={tableOptions?.limit?.toString() || ""}
                              onChange={e => {
                                setTableOptions({
                                  ...tableOptions,
                                  limit: parseInt(e.target.value || 0),
                                })
                              }}
                              onKeyDown={handleLimitSubmit}
                            />
                          </div>
                          <div>
                            <a
                              className="btn"
                              onClick={() => {
                                toggleTableOptions()
                              }}
                            >
                              <i className="bx bx-cog"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                      <table className="table table-bordered mb-4" id="ptable">
                        <thead>
                          <tr>
                            {tableSettings?.settings.map((item, index) =>
                              item.visible ? (
                                <th key={index} className={item.classNames}>
                                  <a
                                    onClick={() => {
                                      if (tableOptions?.orderBy == item.key) {
                                        setTableOptions({
                                          ...tableOptions,
                                          ascDesc:
                                            tableOptions.ascDesc == 1 ? -1 : 1,
                                        })
                                      } else {
                                        setTableOptions({
                                          ...tableOptions,
                                          orderBy: item.key,
                                          ascDesc: 1,
                                        })
                                      }
                                      refresh()
                                    }}
                                  >
                                    {props.t(item.title)}
                                    <i
                                      className={
                                        "bx " +
                                        (tableOptions?.orderBy == item.key
                                          ? tableOptions?.ascDesc == 1
                                            ? "bx-sort-a-z ordered"
                                            : "bx-sort-z-a ordered"
                                          : "bx-sort-a-z nonOrder") +
                                        " float-end"
                                      }
                                    ></i>
                                  </a>
                                </th>
                              ) : null
                            )}

                            <th className="td-copy">{props.t("İşlem")}</th>
                            <th className="td-coupons">
                              {props.t("Kuponlar")}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {list.map((item, index) => (
                            <tr key={index}>
                              {tableSettings?.settings.map(
                                (itemHead, indexHead) =>
                                  itemHead.visible ? (
                                    <td key={index + "-" + indexHead}>
                                      {getInput(item, index, indexHead)}
                                    </td>
                                  ) : null
                              )}
                              <td className="text-center">
                                <a
                                  // href="#"
                                  className="link"
                                  onClick={() => {
                                    let selectedRow = list[index]
                                    setOrder({
                                      faturaNo: "",
                                      firstName: selectedRow.firstName,
                                      lastName: selectedRow.lastName,
                                      phone: selectedRow.phone,
                                      amount: "",
                                      dateOfBirth: moment(
                                        selectedRow.dateOfBirth
                                      ).format("YYYY-MM-DD"),
                                      country: selectedRow.country,
                                      town: selectedRow.town,
                                      address: selectedRow.address,
                                    })
                                    toggleDetail()
                                  }}
                                >
                                  <i className="bx bx-copy"></i> Kopyala
                                </a>
                              </td>
                              <td className="">
                                <a
                                  // href="#"
                                  className="link"
                                  onClick={() => {
                                    console.log(list[index])
                                    setSelectedRow(list[index])
                                    togglePreview()
                                  }}
                                >
                                  {list[index]?.counponIdList?.length} Adet
                                  Kupun
                                </a>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>

                      {tableOptions ? (
                        <>
                          <div className="d-flex flex-row float-end">
                            <Button
                              className="me-1 btn-sm"
                              color="light"
                              disabled={
                                tableOptions.page <= 1 ||
                                tableOptions.pageCount == 1
                              }
                              onClick={() => {
                                let temp = { ...tableOptions }
                                temp.page = tableOptions.page - 1
                                setTableOptions(temp)
                                getListManuel(temp)
                              }}
                            >
                              <i className="bx bx-chevron-left"></i>
                            </Button>
                            {tableOptions?.paging?.map((item, idx) => {
                              if (
                                idx > 0 &&
                                tableOptions.paging[idx] -
                                  tableOptions.paging[idx - 1] >
                                  1
                              ) {
                                return (
                                  <div key={idx} className="d-flex flex-row">
                                    <Button
                                      className="me-1 btn-sm"
                                      color={
                                        tableOptions.page == item
                                          ? "dark"
                                          : "light"
                                      }
                                      disabled={true}
                                    >
                                      ...
                                    </Button>
                                    <Button
                                      className="me-1 btn-sm"
                                      color="light"
                                      onClick={() => {
                                        let temp = {
                                          ...tableOptions,
                                        }
                                        temp.page = item
                                        setTableOptions(temp)
                                        getListManuel(temp)
                                      }}
                                    >
                                      {item}
                                    </Button>
                                  </div>
                                )
                              } else {
                                return (
                                  <Button
                                    key={idx}
                                    className="me-1 btn-sm"
                                    color={
                                      tableOptions.page == item
                                        ? "dark"
                                        : "light"
                                    }
                                    onClick={() => {
                                      let temp = { ...tableOptions }
                                      temp.page = item
                                      setTableOptions(temp)
                                      getListManuel(temp)
                                    }}
                                  >
                                    {item}
                                  </Button>
                                )
                              }
                            })}
                            <Button
                              className="me-1 btn-sm"
                              color="light"
                              disabled={
                                tableOptions.page >= tableOptions.pageCount ||
                                tableOptions.pageCount == 1
                              }
                              onClick={() => {
                                let temp = { ...tableOptions }
                                temp.page = tableOptions.page + 1
                                setTableOptions(temp)
                                getListManuel(temp)
                              }}
                            >
                              <i className="bx bx-chevron-right"></i>
                            </Button>
                          </div>
                        </>
                      ) : null}

                      {/* {!isEmpty(tableOptions) && !isEmpty(tableOptions.paging) ? (
                      <div>
                        {tableOptions.paging.map((item, index) =>
                          item.selected ? (
                            <a className="btn btn-primary" key={index}>
                              {item.title}
                            </a>
                          ) : (
                            <a
                              className="btn btn-default"
                              key={index}
                              onClick={() => {
                                setTableOptions({
                                  ...tableOptions,
                                  selectedPage: item.title,
                                })
                                refresh()
                              }}
                            >
                              {item.title}
                            </a>
                          )
                        )}
                      </div>
                    ) : null} */}
                    </div>
                  )}
                </CardBody>
              </Card>

              <Modal isOpen={detailModal} toggle={toggleDetail}>
                <ModalHeader toggle={toggleDetail} tag="h4">
                  Yeni Kayıt
                </ModalHeader>
                <ModalBody>
                  <Form
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <Row form>
                      <Col className="col-12">
                        <div className="mb-3">
                          <Label className="form-label">Fatura No</Label>
                          <Input
                            name="faturaNo"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.faturaNo || ""}
                            invalid={
                              validation.touched.faturaNo &&
                              validation.errors.faturaNo
                                ? true
                                : false
                            }
                          />
                          {validation.touched.faturaNo &&
                          validation.errors.faturaNo ? (
                            <FormFeedback type="invalid">
                              {validation.errors.faturaNo}
                            </FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label className="form-label">Tutar</Label>
                          <Input
                            name="amount"
                            type="number"
                            step="0.01"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.amount || ""}
                            invalid={
                              validation.touched.amount &&
                              validation.errors.amount
                                ? true
                                : false
                            }
                          />
                          {validation.touched.amount &&
                          validation.errors.amount ? (
                            <FormFeedback type="invalid">
                              {validation.errors.amount}
                            </FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label className="form-label">Adı</Label>
                          <Input
                            name="firstName"
                            type="text"
                            validate={{
                              required: { value: true },
                            }}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.firstName || ""}
                            invalid={
                              validation.touched.firstName &&
                              validation.errors.firstName
                                ? true
                                : false
                            }
                          />
                          {validation.touched.firstName &&
                          validation.errors.firstName ? (
                            <FormFeedback type="invalid">
                              {validation.errors.firstName}
                            </FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label className="form-label">Soyadı</Label>
                          <Input
                            name="lastName"
                            type="text"
                            validate={{
                              required: { value: true },
                            }}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.lastName || ""}
                            invalid={
                              validation.touched.lastName &&
                              validation.errors.lastName
                                ? true
                                : false
                            }
                          />
                          {validation.touched.lastName &&
                          validation.errors.lastName ? (
                            <FormFeedback type="invalid">
                              {validation.errors.lastName}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <Label className="form-label">Doğum Tarihi</Label>
                          <Input
                            name="dateOfBirth"
                            type="date"
                            // value={orderList.dateOfBirth || ""}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.dateOfBirth || ""}
                            invalid={
                              validation.touched.dateOfBirth &&
                              validation.errors.dateOfBirth
                                ? true
                                : false
                            }
                          />
                          {validation.touched.dateOfBirth &&
                          validation.errors.dateOfBirth ? (
                            <FormFeedback type="invalid">
                              {validation.errors.dateOfBirth}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <Label className="form-label">Telefon</Label>
                          <InputMask
                            mask="(999) 999-9999"
                            maskChar="-"
                            placeholder="(999) 999-9999"
                            validate={{
                              required: { value: true },
                            }}
                            value={validation.values.phone || ""}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.phone &&
                              validation.errors.phone
                                ? true
                                : false
                            }
                          >
                            {inputProps => (
                              <Input {...inputProps} name="phone" type="text" />
                            )}
                          </InputMask>
                          {validation.touched.phone &&
                          validation.errors.phone ? (
                            <FormFeedback type="invalid">
                              {validation.errors.phone}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <Label className="form-label">İl</Label>
                          <Input
                            name="country"
                            type="text"
                            validate={{
                              required: { value: true },
                            }}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.country || ""}
                            invalid={
                              validation.touched.country &&
                              validation.errors.country
                                ? true
                                : false
                            }
                          />
                          {validation.touched.country &&
                          validation.errors.country ? (
                            <FormFeedback type="invalid">
                              {validation.errors.country}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <Label className="form-label">İlçe</Label>
                          <Input
                            name="town"
                            type="text"
                            validate={{
                              required: { value: true },
                            }}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.town || ""}
                            invalid={
                              validation.touched.town && validation.errors.town
                                ? true
                                : false
                            }
                          />
                          {validation.touched.town && validation.errors.town ? (
                            <FormFeedback type="invalid">
                              {validation.errors.town}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <Label className="form-label">Adres</Label>
                          <Input
                            name="address"
                            type="textarea"
                            validate={{
                              required: { value: true },
                            }}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.address || ""}
                            invalid={
                              validation.touched.address &&
                              validation.errors.address
                                ? true
                                : false
                            }
                          />
                          {validation.touched.address &&
                          validation.errors.address ? (
                            <FormFeedback type="invalid">
                              {validation.errors.address}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="text-end">
                          <button
                            type="submit"
                            className="btn btn-success save-user"
                          >
                            Kaydet
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </ModalBody>
              </Modal>

              <Modal isOpen={previewModal} toggle={togglePreview} size={"lg"}>
                <ModalHeader toggle={togglePreview} tag="h4">
                  Kupon Detayı
                </ModalHeader>
                <ModalBody>
                  {!isEmpty(selectedRow) ? (
                    <>
                      <div id="couponpreview">
                        <Row
                          style={{ marginRight: 20, marginLeft: 20 }}
                          className="print-page"
                        >
                          <Col
                            className="col-12"
                            style={{ textAlign: "center" }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <img
                                src={header_img_1}
                                style={{
                                  width: 75,
                                  height: 75,
                                  objectFit: "contain",
                                }}
                              />
                              <img
                                src={_logo}
                                style={{
                                  width: 150,
                                  height: 75,
                                  objectFit: "contain",
                                }}
                              />
                              {/* <div style={{ width: 75 }}></div> */}
                            </div>
                            {selectedRow.status != "ok" ? (
                              <div className="mb-3">
                                <h2 style={{ color: "#FF0000" }}>İPTAL</h2>
                                <p style={{ color: "#FF0000" }}>
                                  {selectedRow.description}
                                </p>
                              </div>
                            ) : null}
                            <div className="mb-3">
                              <Label className="mb-1">Bilet Numaraları</Label>
                              <div
                                style={
                                  {
                                    // display: "flex",
                                    // flexWrap: "wrap",
                                    // justifyContent: "center",
                                    // alignItems: "center",
                                    // fontWeight: "bold",
                                    // fontSize: "14px",
                                  }
                                }
                              >
                                {selectedRow.counponIdList?.map((id, index) => (
                                  <span key={index} className="coupon-number">
                                    {String(id).padStart(7, "0")}
                                    <br />
                                  </span>
                                ))}
                              </div>
                            </div>
                            <hr />

                            <div className="mb-3">
                              <Label className="mb-1">
                                Katılımcı Bilgileri
                              </Label>
                              <p className="mb-1">
                                {selectedRow.firstName +
                                  " " +
                                  selectedRow.lastName +
                                  "  " +
                                  selectedRow.phone}
                              </p>
                              <p className="mb-1">
                                {selectedRow.address} {selectedRow.town} /{" "}
                                {selectedRow.country}
                              </p>
                            </div>
                            <hr />
                            <div
                              className="mb-1"
                              style={{
                                justifyContent: "center",
                                alignItems: "center",
                                display: "flex",
                                flexDirection: "column",
                                alignContent: "center",
                              }}
                            >
                              <table style={{ border: 0 }}>
                                <tbody>
                                  <tr>
                                    <td style={{ textAlign: "left" }}>
                                      <b style={{ marginRight: 10 }}>
                                        Fatura No:
                                      </b>
                                    </td>
                                    <td style={{ textAlign: "right" }}>
                                      {selectedRow.faturaNo}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style={{ textAlign: "left" }}>
                                      <b style={{ marginRight: 10 }}>Tutar:</b>
                                    </td>
                                    <td style={{ textAlign: "right" }}>
                                      {FormatTLBasic(selectedRow.amount)}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style={{ textAlign: "left" }}>
                                      <b style={{ marginRight: 10 }}>
                                        İşlem Tarihi:
                                      </b>
                                    </td>
                                    <td style={{ textAlign: "right" }}>
                                      {handleDateTime(selectedRow.createdAt)}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style={{ textAlign: "left" }}>
                                      <b style={{ marginRight: 10 }}>
                                        Çekiliş İzni:
                                      </b>
                                    </td>
                                    <td style={{ textAlign: "right" }}>
                                      {opt?.extra?.permission}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style={{ textAlign: "left" }}>
                                      <b style={{ marginRight: 10 }}>
                                        Çekiliş Konusu:
                                      </b>
                                    </td>
                                    <td style={{ textAlign: "right" }}>
                                      {opt?.extra?.about}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style={{ textAlign: "left" }}>
                                      <b style={{ marginRight: 10 }}>
                                        Çekiliş Tarihi:
                                      </b>
                                    </td>
                                    <td style={{ textAlign: "right" }}>
                                      {opt?.extra?.date}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <table>
                                <tbody>
                                  <tr>
                                    <td style={{ textAlign: "center" }}>
                                      {opt?.extra?.address}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <p
                                style={{
                                  fontSize: 8,
                                  color: "#5b5b5b",
                                }}
                              >
                                #{selectedRow?._id}
                              </p>
                            </div>
                          </Col>
                        </Row>
                      </div>
                      <div id="coupons-print" style={{ display: "none" }}>
                        {Array.from(
                          {
                            length: parseInt(
                              selectedRow.counponIdList.length /
                                couponMaxItems +
                                1
                            ),
                          },
                          (_, index) => {
                            return (
                              <div key={index}>
                                <div className="page-break"></div>
                                <Row
                                  style={{ marginRight: 20, marginLeft: 20 }}
                                  className="print-page"
                                >
                                  <div style={{ textAlign: "right" }}>
                                    <span
                                      style={{
                                        fontSize: 8,
                                        color: "#b5b5b5",
                                      }}
                                    >
                                      Sayfa: {index + 1}/
                                      {parseInt(
                                        selectedRow.counponIdList.length /
                                          couponMaxItems +
                                          1
                                      )}
                                    </span>
                                  </div>
                                  <Col
                                    className="col-12"
                                    style={{ textAlign: "center" }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                      }}
                                    >
                                      <img
                                        src={header_img_1}
                                        style={{
                                          width: 75,
                                          height: 75,
                                          objectFit: "contain",
                                        }}
                                      />
                                      <img
                                        src={_logo}
                                        style={{
                                          width: 150,
                                          height: 75,
                                          objectFit: "contain",
                                        }}
                                      />
                                      {/* <div style={{ width: 75 }}></div> */}
                                    </div>
                                    {selectedRow.status != "ok" ? (
                                      <div className="mb-3">
                                        <h2 style={{ color: "#FF0000" }}>
                                          İPTAL
                                        </h2>
                                        <p style={{ color: "#FF0000" }}>
                                          {selectedRow.description}
                                        </p>
                                      </div>
                                    ) : null}
                                    <div className="mb-3">
                                      <Label
                                        className="mb-1"
                                        style={{
                                          fontSize: 12,
                                          marginBottom: 5,
                                        }}
                                      >
                                        Bilet Numaraları
                                      </Label>
                                      <div
                                        style={
                                          {
                                            // display: "flex",
                                            // flexWrap: "wrap",
                                            // justifyContent: "center",
                                            // alignItems: "center",
                                            // fontWeight: "bold",
                                            // fontSize: "14px",
                                          }
                                        }
                                      >
                                        {selectedRow.counponIdList?.map(
                                          (id, indexAlt) => {
                                            if (
                                              indexAlt >=
                                                index * couponMaxItems &&
                                              indexAlt <
                                                (index + 1) * couponMaxItems
                                            )
                                              return (
                                                <span
                                                  key={indexAlt}
                                                  className="coupon-number"
                                                >
                                                  {String(id).padStart(7, "0")}
                                                  <br />
                                                </span>
                                              )
                                          }
                                        )}
                                      </div>
                                    </div>
                                    <hr />

                                    <div className="mb-3">
                                      <Label className="mb-1">
                                        Katılımcı Bilgileri
                                      </Label>
                                      <p className="mb-1">
                                        {selectedRow.firstName +
                                          " " +
                                          selectedRow.lastName +
                                          "  " +
                                          selectedRow.phone}
                                      </p>
                                      <p className="mb-1">
                                        {selectedRow.address} {selectedRow.town}{" "}
                                        / {selectedRow.country}
                                      </p>
                                    </div>
                                    <hr />
                                    <div
                                      className="mb-1"
                                      style={{
                                        justifyContent: "center",
                                        alignItems: "center",
                                        display: "flex",
                                        flexDirection: "column",
                                        alignContent: "center",
                                      }}
                                    >
                                      <table style={{ border: 0 }}>
                                        <tbody>
                                          <tr>
                                            <td style={{ textAlign: "left" }}>
                                              <b style={{ marginRight: 10 }}>
                                                Fatura No:
                                              </b>
                                            </td>
                                            <td style={{ textAlign: "right" }}>
                                              {selectedRow.faturaNo}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td style={{ textAlign: "left" }}>
                                              <b style={{ marginRight: 10 }}>
                                                Tutar:
                                              </b>
                                            </td>
                                            <td style={{ textAlign: "right" }}>
                                              {FormatTLBasic(
                                                selectedRow.amount
                                              )}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td style={{ textAlign: "left" }}>
                                              <b style={{ marginRight: 10 }}>
                                                İşlem Tarihi:
                                              </b>
                                            </td>
                                            <td style={{ textAlign: "right" }}>
                                              {handleDateTime(
                                                selectedRow.createdAt
                                              )}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td style={{ textAlign: "left" }}>
                                              <b style={{ marginRight: 10 }}>
                                                Çekiliş İzni:
                                              </b>
                                            </td>
                                            <td style={{ textAlign: "right" }}>
                                              {opt?.extra?.permission}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td style={{ textAlign: "left" }}>
                                              <b style={{ marginRight: 10 }}>
                                                Çekiliş Konusu:
                                              </b>
                                            </td>
                                            <td style={{ textAlign: "right" }}>
                                              {opt?.extra?.about}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td style={{ textAlign: "left" }}>
                                              <b style={{ marginRight: 10 }}>
                                                Çekiliş Tarihi:
                                              </b>
                                            </td>
                                            <td style={{ textAlign: "right" }}>
                                              {opt?.extra?.date}
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <table>
                                        <tbody>
                                          <tr>
                                            <td style={{ textAlign: "center" }}>
                                              {opt?.extra?.address}
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <p
                                        style={{
                                          marginTop: 5,
                                          fontSize: 8,
                                          color: "#5b5b5b",
                                        }}
                                      >
                                        #{selectedRow?._id}
                                      </p>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            )
                          }
                        )}
                      </div>
                    </>
                  ) : null}
                  <Row>
                    <Col>
                      <iframe
                        id="ifmcontentstoprint"
                        style={{ height: 0, width: 0, position: "absolute" }}
                      ></iframe>
                      <div className="text-end">
                        {selectedRow?.status == "ok" ? (
                          <button
                            type="button"
                            className="btn btn-danger me-2"
                            onClick={() => {
                              toggleVoid()
                            }}
                          >
                            İptal
                          </button>
                        ) : null}
                        <button
                          type="button"
                          className="btn btn-success"
                          onClick={() => {
                            var content =
                              document.getElementById("coupons-print")
                            if (content) {
                              content.style.display = "block"
                              var pri =
                                document.getElementById(
                                  "ifmcontentstoprint"
                                ).contentWindow
                              if (pri) {
                                pri.document.open()

                                pri.document.write(
                                  "<div>" +
                                    content.innerHTML +
                                    "</div>" +
                                    "<style>label{font-weight:600;margin-bottom:0.25rem !important; }" +
                                    "p, tr{font-size:8px;}" +
                                    ".coupon-number { display: inline-block; padding: 5px; margin: 2px;  border: 0.5px solid #ccc; border-radius: 4px; text-align: center;font-weight: bold; font-size: 12px }" +
                                    ".page-break { page-break-before: always;}" +
                                    ".print-page { border:1px solid #000;text-align:center; font-size:8px; margin-bottom: 10px; margin-top: 20px; padding: 10px; border-radius:4px}" +
                                    "</style>"
                                )

                                content.style.display = "none"
                                pri.document.close()
                                pri.focus()
                                pri.print()
                              }
                            }
                          }}
                        >
                          Yazdır
                        </button>
                      </div>
                    </Col>
                  </Row>
                </ModalBody>
              </Modal>

              <Modal isOpen={voidModal} toggle={toggleVoid}>
                <ModalHeader toggle={toggleVoid} tag="h4">
                  İptal
                </ModalHeader>
                <ModalBody>
                  <Form
                    onSubmit={e => {
                      e.preventDefault()
                      validation2.handleSubmit()
                      return false
                    }}
                  >
                    <Row form>
                      <Col className="col-12">
                        <div className="mb-3">
                          <Label className="form-label">Açıklama</Label>
                          <Input
                            name="description"
                            type="textarea"
                            validate={{
                              required: { value: true },
                            }}
                            onChange={validation2.handleChange}
                            onBlur={validation2.handleBlur}
                            value={validation2.values.description || ""}
                            invalid={
                              validation2.touched.description &&
                              validation2.errors.description
                                ? true
                                : false
                            }
                          />
                          {validation2.touched.description &&
                          validation2.errors.description ? (
                            <FormFeedback type="invalid">
                              {validation2.errors.description}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="text-end">
                          <button type="submit" className="btn btn-danger">
                            Kaydet
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </ModalBody>
              </Modal>

              <Modal
                isOpen={modalTableOptions}
                toggle={toggleTableOptions}
                scrollable={true}
              >
                <ModalHeader toggle={toggleTableOptions} tag="h4">
                  {props.t("Tablo Ayarları")}
                </ModalHeader>
                <ModalBody>
                  <div className="table-responsive relative">
                    {loadingView()}
                    <ReactDragListView {...dragProps}>
                      <Table className="table mb-0">
                        <tbody>
                          {(tableSettings?.settings || []).map((item, index) =>
                            !item.hiddenOption ? (
                              <tr key={index}>
                                <td>
                                  <input
                                    id={"topt-" + item.key}
                                    name={"topt-" + item.key}
                                    type={"checkbox"}
                                    defaultChecked={item.visible}
                                    onClick={e => {
                                      changeInputTableSettings(
                                        index,
                                        e.target.checked,
                                        "visible"
                                      )
                                    }}
                                  />{" "}
                                </td>
                                <th scope="row" className="drag-pointer">
                                  {props.t(item.title)}
                                </th>
                              </tr>
                            ) : null
                          )}
                        </tbody>
                      </Table>
                    </ReactDragListView>
                  </div>
                </ModalBody>
              </Modal>
              {error && error ? (
                <SweetAlert
                  title={"Hata"}
                  warning
                  confirmBtnText={"Tamam"}
                  onConfirm={() => {
                    dispatch(onResetCoupon())
                  }}
                >
                  {JSON.stringify(error)}
                </SweetAlert>
              ) : null}
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}
Dashboard_v2.propTypes = {
  t: PropTypes.any,
}

export default withRouter(withTranslation()(Dashboard_v2))
