import {
    GET_COUPONS,
    GET_COUPONS_SUCCESS,
    GET_COUPONS_FAIL,
    GET_COUPONS_REPORT,
    GET_COUPONS_REPORT_SUCCESS,
    GET_COUPONS_REPORT_FAIL,
    GET_COUPON_DETAIL,
    GET_COUPON_DETAIL_SUCCESS,
    GET_COUPON_DETAIL_FAIL,
    ADD_COUPON,
    ADD_COUPON_SUCCESS,
    ADD_COUPON_FAIL,
    UPDATE_COUPON,
    UPDATE_COUPON_SUCCESS,
    UPDATE_COUPON_FAIL,
    DELETE_COUPON,
    DELETE_COUPON_SUCCESS,
    DELETE_COUPON_FAIL,
    RESET_COUPON,
    GET_COUPONS_SERVERSIDE,
    GET_COUPONS_SERVERSIDE_SUCCESS,
    GET_COUPONS_SERVERSIDE_FAIL, 
} from "./actionTypes";
 
export const resetCoupon = () => ({
    type: RESET_COUPON,
});

export const getCoupons = () => ({
    type: GET_COUPONS,
});

export const getCouponsSuccess = (data) => ({
    type: GET_COUPONS_SUCCESS,
    payload: data,
});
 
export const getCouponsFail = (error) => ({
    type: GET_COUPONS_FAIL,
    payload: error,
});

export const getCouponsServerSide = (page, limit, select, orderBy, ascDesc) =>({
    type: GET_COUPONS_SERVERSIDE,
    payload: {page, limit, select, orderBy, ascDesc}
});

export const getCouponsServerSideSuccess = (data) => ({
    type: GET_COUPONS_SERVERSIDE_SUCCESS,
    payload: data,
});
 
export const getCouponsServerSideFail = (error) => ({
    type: GET_COUPONS_SERVERSIDE_FAIL,
    payload: error,
});

export const getCouponsReport = () => ({
    type: GET_COUPONS_REPORT,
});

export const getCouponsReportSuccess = (data) => ({
    type: GET_COUPONS_REPORT_SUCCESS,
    payload: data,
});


export const getCouponsReportFail = (error) => ({
    type: GET_COUPONS_REPORT_FAIL,
    payload: error,
});

export const addCoupon = (data) => ({
    type: ADD_COUPON,
    payload: data
});

export const addCouponSuccess = (data) => ({
    type: ADD_COUPON_SUCCESS,
    payload: data
});

export const addCouponFail = (error) => ({
    type: ADD_COUPON_FAIL,
    payload: error,
});


export const updateCoupon = (data) => ({
    type: UPDATE_COUPON,
    payload: data
});

export const updateCouponSuccess = (data) => ({
    type: UPDATE_COUPON_SUCCESS,
    payload: data
});

export const updateCouponFail = (error) => ({
    type: UPDATE_COUPON_FAIL,
    payload: error,
});
 
export const deleteCoupon = (id) => ({
    type:  DELETE_COUPON,
    payload: id
});

export const deleteCouponSuccess = (data) => ({
    type: DELETE_COUPON_SUCCESS,
    payload: data
});

export const deleteCouponFail = (error) => ({
    type: DELETE_COUPON_FAIL,
    payload: error,
});
