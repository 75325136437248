//REGISTER
export const POST_REGISTER = "/api/users"
export const POST_FAKE_REGISTER = "/api/users"

//LOGIN
export const POST_LOGIN = "/auth/token"
export const POST_FAKE_LOGIN = "/post-fake-login"
export const POST_FAKE_JWT_LOGIN = "/post-jwt-login"
export const POST_FAKE_PASSWORD_FORGET = "/fake-forget-pwd"
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd"
export const SOCIAL_LOGIN = "/social-login"

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile"
export const POST_EDIT_PROFILE = "/post-fake-profile"
 

//COMPANIES
export const GET_COMPANIES = "/api/companies"

export const GET_COMPANY_DETAIL = "/api/companies"
export const ADD_NEW_COMPANY = "/api/companies"
export const UPDATE_COMPANY = "/api/companies"
export const DELETE_COMPANY = "/api/companies"

//coupons
export const GET_COUPONS_REPORT = "/api/coupons/report" 
export const GET_COUPONS = "/api/coupons"   
export const GET_COUPONS_SERVERSIDE = "/api/coupons/list-serverside"  
export const GET_COUPON_DETAIL = "/api/coupons"    
export const ADD_COUPONS = "/api/coupons"   
export const UPDATE_COUPONS = "/api/coupons"
export const DELETE_COUPONS = "/api/coupons"
 

//usercompany
export const UPDATE_USERCOMPANY = "/api/users/addCompany"

export const GET_ROLES = "/api/roles"
export const GET_USER = "/api/users"
export const GET_USER_DETAIL = "/api/users"
export const ADD_NEW_USERS = "/api/users"
export const UPDATE_USERS = "/api/users"
export const DELETE_USERS = "/api/users"
export const GET_COMPANY_BY_EMAIL = "/api/users"
export const FORGOT_SEND_MAIL = "/auth/forgotPassword"
export const SEND_MAIL = "/auth/mailtest"
export const EMAIL_CONFIRM = "/auth/confirm"






//CONTACTS
export const GET_USERS = "/users"
export const GET_USER_PROFILE = "/user"
export const ADD_NEW_USER = "/add/user"
export const UPDATE_USER = "/update/user"
export const DELETE_USER = "/del/user"