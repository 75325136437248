import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap"
import InputMask from "react-input-mask"
import MetaTags from "react-meta-tags"

import { useSelector, useDispatch } from "react-redux"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"

import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit"

import { withRouter } from "react-router-dom"
//i18n
import { withTranslation } from "react-i18next"
import { FormatTLBasic } from "./../../components/turkishMoneyFormat"

const { ExportCSVButton } = CSVExport

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "./datatables.scss"

import {
  getCoupons as onGetCoupons,
  addCoupon as onAddNewCoupon,
  updateCoupon as onUpdateCoupon,
  resetCoupon as onResetCoupon,
} from "store/actions"
import { isEmpty, map } from "lodash"
import moment from "moment"

import * as Yup from "yup"
import { useFormik } from "formik"

import SweetAlert from "react-bootstrap-sweetalert"
import _logo from "./../../assets/images/Kutup.png"

import header_img_1 from "./../../assets/images/coupons/kutup-qrcode.png"
import header_img_2 from "./../../assets/images/coupons/Logo_kutup_QR3.jpg"

const Dashboard = props => {
  const stopProject = process.env.REACT_APP_STOP_PROJECT === "true"
  const dispatch = useDispatch()
  const { error, data } = useSelector(state => ({
    error: state.Coupon?.error,
    data: state.Coupon?.data || [],
  }))

  const [detailModal, setDetailModal] = useState(false)
  const [voidModal, setVoidModal] = useState(false)
  const [previewModal, setPreviewModal] = useState(false)
  const [selectedRow, setSelectedRow] = useState(null)
  const [order, setOrder] = useState(null)

  useEffect(() => {
    dispatch(onGetCoupons())
  }, [dispatch])

  const handleDate = date => {
    if (isEmpty(date)) {
      return "--"
    }
    return moment(date).format("DD.MM.YYYY")
  }

  const toggleDetail = () => {
    setDetailModal(!detailModal)
  }

  const togglePreview = () => {
    setPreviewModal(!previewModal)
  }

  const toggleVoid = () => {
    setVoidModal(!voidModal)
  }
  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      faturaNo: (order && order.faturaNo) || "",
      firstName: (order && order.firstName) || "",
      lastName: (order && order.lastName) || "",
      amount: (order && order.amount) || "",
      phone: (order && order.phone) || "",
      country: (order && order.country) || "",
      town: (order && order.town) || "",
      address: (order && order.address) || "",
      dateOfBirth: (order && order.dateOfBirth) || "",
    },
    validationSchema: Yup.object({
      faturaNo: Yup.string().required("* Zorunlu"),
      firstName: Yup.string().required("* Zorunlu"),
      lastName: Yup.string().required("* Zorunlu"),
      amount: Yup.string().required("* Zorunlu"),
      phone: Yup.string().required("* Zorunlu"),
      country: Yup.string().required("* Zorunlu"),
      town: Yup.string().required("* Zorunlu"),
      address: Yup.string().required("* Zorunlu"),
      dateOfBirth: Yup.string().required("* Zorunlu"),
    }),
    onSubmit: values => {
      const newOrder = {
        //id: Math.floor(Math.random() * (30 - 20)) + 20,
        faturaNo: values["faturaNo"],
        firstName: values["firstName"],
        lastName: values["lastName"],
        amount: values["amount"],
        phone: values["phone"],
        country: values["country"],
        town: values["town"],
        address: values["address"],
        dateOfBirth: values["dateOfBirth"],
      }
      // save new order
      dispatch(onAddNewCoupon(newOrder))
      validation.resetForm()
      toggleDetail()
    },
  })

  // validation
  const validation2 = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      description: (order && order.description) || "",
    },
    validationSchema: Yup.object({
      description: Yup.string().required("* Zorunlu"),
    }),
    onSubmit: values => {
      const updateOrder = {
        id: selectedRow._id,
        description: values["description"],
        status: "void",
      }
      console.log(updateOrder)
      // save new order
      dispatch(onUpdateCoupon(updateOrder))
      validation.resetForm()
      toggleVoid()
      togglePreview()
    },
  })

  const columns = [
    {
      dataField: "counponIdMax",
      text: "Id",
      sort: true,
    },
    {
      dataField: "faturaNo",
      text: "Fatura No",
      sort: true,
    },
    {
      dataField: "firstName",
      text: "Adı",
      sort: true,
    },
    {
      dataField: "lastName",
      text: "Soyadı",
      sort: true,
    },
    {
      dataField: "dateOfBirth",
      text: "Doğum Tarihi",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cell, row) => handleDate(cell),
    },
    {
      dataField: "phone",
      text: "Telefon",
      sort: true,
    },
    {
      dataField: "country",
      text: "İl",
      sort: true,
    },
    {
      dataField: "town",
      text: "İlçe",
      sort: true,
    },
    {
      dataField: "address",
      text: "Adres",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cell, row) =>
        isEmpty(cell)
          ? ""
          : cell.length > 15
          ? cell.substring(0, 15) + "..."
          : cell,
    },
    {
      dataField: "status",
      text: "Durum",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cell, row) => (
        <>
          {cell == "ok" ? (
            <span className="text-success">
              <i className="bx bx-comment-check"></i> Onaylı
            </span>
          ) : (
            <span className="text-danger">
              <i className="bx bx-comment-check"></i> İptal
            </span>
          )}
        </>
      ),
    },
    {
      dataField: "counponIdList",
      text: "Kuponlar",
      sort: false,
      formatter: (cell, row) => {
        return (
          <a
            href="#"
            onClick={() => {
              console.log(row)
              setSelectedRow(row)
              togglePreview()
            }}
          >
            {cell?.length} Adet Kupun
          </a>
        )
      },
    },
  ]

  const defaultSorted = [
    {
      dataField: "counponIdMax",
      order: "desc",
    },
  ]

  const pageOptions = {
    sizePerPage: 50,
    totalSize: isEmpty(data) ? 0 : data.length, // replace later with size(customers),
    custom: true,
  }

  // Custom Pagination Toggle
  const sizePerPageList = [
    { text: "5", value: 5 },
    { text: "10", value: 10 },
    { text: "15", value: 15 },
    { text: "20", value: 20 },
    { text: "25", value: 25 },
    { text: "All", value: isEmpty(data) ? 0 : data.length },
  ]

  const { SearchBar } = Search

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Kuponlar</title>
        </MetaTags>
        <div className="container-fluid">
          <Breadcrumbs
            title="Tablolar"
            breadcrumbItem="Kuponlar"
            addBtn={!stopProject}
            addBtnCallBack={() => {
              setOrder({
                faturaNo: "",
                firstName: "",
                lastName: "",
                phone: "",
                amount: "",
                dateOfBirth: "",
                country: "",
                town: "",
                address: "",
              })
              toggleDetail()
            }}
          />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  {/* <CardTitle className="h4">Rapor</CardTitle> */}
                  {error && error ? (
                    <Alert color="danger">{error}</Alert>
                  ) : null}

                  {stopProject && stopProject ? (
                    <Alert color="danger">Hizmet Durduruldu</Alert>
                  ) : null}

                  {!stopProject && (
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField="id"
                      columns={columns}
                      data={data}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="counponIdMax"
                          columns={columns}
                          data={data}
                          search
                          exportCSV={{
                            fileName: "report.csv",
                            noAutoBOM: false,
                            blobType: "text/plain;charset=utf-8",
                            exportAll: true,
                          }}
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              {/* <ExportCSVButton
                              {...toolkitProps.csvProps}
                              className="btn btn-success"
                            >
                              <i
                                className="mdi mdi-microsoft-excel me-2"
                                style={{ fontSize: 18, padding: 0 }}
                              ></i>
                              EXCEL
                            </ExportCSVButton>
                            <hr /> */}
                              <Row className="mb-2">
                                <Col md="4">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitProps.searchProps}
                                        placeholder="Ara..."
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                              </Row>

                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField={"counponIdMax"}
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                      classes={
                                        "table align-middle table-nowrap"
                                      }
                                      headerWrapperClasses={"thead-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />
                                  </div>
                                </Col>
                              </Row>

                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    {/* <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  /> */}
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  )}
                </CardBody>
              </Card>

              <Modal isOpen={detailModal} toggle={toggleDetail}>
                <ModalHeader toggle={toggleDetail} tag="h4">
                  Yeni Kayıt
                </ModalHeader>
                <ModalBody>
                  <Form
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <Row form>
                      <Col className="col-12">
                        <div className="mb-3">
                          <Label className="form-label">Fatura No</Label>
                          <Input
                            name="faturaNo"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.faturaNo || ""}
                            invalid={
                              validation.touched.faturaNo &&
                              validation.errors.faturaNo
                                ? true
                                : false
                            }
                          />
                          {validation.touched.faturaNo &&
                          validation.errors.faturaNo ? (
                            <FormFeedback type="invalid">
                              {validation.errors.faturaNo}
                            </FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label className="form-label">Tutar</Label>
                          <Input
                            name="amount"
                            type="number"
                            step="0.01"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.amount || ""}
                            invalid={
                              validation.touched.amount &&
                              validation.errors.amount
                                ? true
                                : false
                            }
                          />
                          {validation.touched.amount &&
                          validation.errors.amount ? (
                            <FormFeedback type="invalid">
                              {validation.errors.amount}
                            </FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label className="form-label">Adı</Label>
                          <Input
                            name="firstName"
                            type="text"
                            validate={{
                              required: { value: true },
                            }}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.firstName || ""}
                            invalid={
                              validation.touched.firstName &&
                              validation.errors.firstName
                                ? true
                                : false
                            }
                          />
                          {validation.touched.firstName &&
                          validation.errors.firstName ? (
                            <FormFeedback type="invalid">
                              {validation.errors.firstName}
                            </FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label className="form-label">Soyadı</Label>
                          <Input
                            name="lastName"
                            type="text"
                            validate={{
                              required: { value: true },
                            }}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.lastName || ""}
                            invalid={
                              validation.touched.lastName &&
                              validation.errors.lastName
                                ? true
                                : false
                            }
                          />
                          {validation.touched.lastName &&
                          validation.errors.lastName ? (
                            <FormFeedback type="invalid">
                              {validation.errors.lastName}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <Label className="form-label">Doğum Tarihi</Label>
                          <Input
                            name="dateOfBirth"
                            type="date"
                            // value={orderList.dateOfBirth || ""}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.dateOfBirth || ""}
                            invalid={
                              validation.touched.dateOfBirth &&
                              validation.errors.dateOfBirth
                                ? true
                                : false
                            }
                          />
                          {validation.touched.dateOfBirth &&
                          validation.errors.dateOfBirth ? (
                            <FormFeedback type="invalid">
                              {validation.errors.dateOfBirth}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <Label className="form-label">Telefon</Label>
                          <InputMask
                            mask="(999) 999-9999"
                            maskChar="-"
                            placeholder="(999) 999-9999"
                            validate={{
                              required: { value: true },
                            }}
                            value={validation.values.phone || ""}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.phone &&
                              validation.errors.phone
                                ? true
                                : false
                            }
                          >
                            {inputProps => (
                              <Input {...inputProps} name="phone" type="text" />
                            )}
                          </InputMask>
                          {validation.touched.phone &&
                          validation.errors.phone ? (
                            <FormFeedback type="invalid">
                              {validation.errors.phone}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <Label className="form-label">İl</Label>
                          <Input
                            name="country"
                            type="text"
                            validate={{
                              required: { value: true },
                            }}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.country || ""}
                            invalid={
                              validation.touched.country &&
                              validation.errors.country
                                ? true
                                : false
                            }
                          />
                          {validation.touched.country &&
                          validation.errors.country ? (
                            <FormFeedback type="invalid">
                              {validation.errors.country}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <Label className="form-label">İlçe</Label>
                          <Input
                            name="town"
                            type="text"
                            validate={{
                              required: { value: true },
                            }}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.town || ""}
                            invalid={
                              validation.touched.town && validation.errors.town
                                ? true
                                : false
                            }
                          />
                          {validation.touched.town && validation.errors.town ? (
                            <FormFeedback type="invalid">
                              {validation.errors.town}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <Label className="form-label">Adres</Label>
                          <Input
                            name="address"
                            type="textarea"
                            validate={{
                              required: { value: true },
                            }}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.address || ""}
                            invalid={
                              validation.touched.address &&
                              validation.errors.address
                                ? true
                                : false
                            }
                          />
                          {validation.touched.address &&
                          validation.errors.address ? (
                            <FormFeedback type="invalid">
                              {validation.errors.address}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="text-end">
                          <button
                            type="submit"
                            className="btn btn-success save-user"
                          >
                            Kaydet
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </ModalBody>
              </Modal>

              <Modal isOpen={previewModal} toggle={togglePreview} size={"lg"}>
                <ModalHeader toggle={togglePreview} tag="h4">
                  Kupon Detayı
                </ModalHeader>
                <ModalBody>
                  {!isEmpty(selectedRow) ? (
                    <div id="couponpreview">
                      <Row style={{ marginRight: 20, marginLeft: 20 }}>
                        <Col className="col-12" style={{ textAlign: "center" }}>
                          <div style={{ position: "relative" }}>
                            <img
                              src={header_img_1}
                              style={{
                                width: 100,
                                objectFit: "contain",
                                position: "absolute",
                                left: 0,
                                top: 10,
                              }}
                            />
                            <img
                              src={_logo}
                              style={{ width: 200, objectFit: "contain" }}
                            />
                          </div>
                          {selectedRow.status != "ok" ? (
                            <div className="mb-3">
                              <h2 style={{ color: "#FF0000" }}>İPTAL</h2>
                              <p style={{ color: "#FF0000" }}>
                                {selectedRow.description}
                              </p>
                            </div>
                          ) : null}
                          <div className="mb-3">
                            <Label className="mb-1">Bilet Numaraları</Label>
                            <h3 style={{ wordBreak: "break-word" }}>
                              {selectedRow.counponIdList?.join(" - ")}
                            </h3>
                          </div>
                          <hr />

                          <div className="mb-3">
                            <Label className="mb-1">Katılımcı Bilgileri</Label>
                            <p className="mb-1">
                              {selectedRow.firstName +
                                " " +
                                selectedRow.lastName +
                                "  " +
                                selectedRow.phone}
                            </p>
                            <p className="mb-1">
                              {selectedRow.address} {selectedRow.town} /{" "}
                              {selectedRow.country}
                            </p>
                          </div>
                          <hr />
                          <div
                            className="mb-1"
                            style={{
                              justifyContent: "center",
                              alignItems: "center",
                              display: "flex",
                            }}
                          >
                            <table style={{ border: 0 }}>
                              <tr>
                                <td style={{ textAlign: "left" }}>
                                  <b style={{ marginRight: 10 }}>Fatura No:</b>
                                </td>
                                <td style={{ textAlign: "right" }}>
                                  {selectedRow.faturaNo}
                                </td>
                              </tr>
                              <tr>
                                <td style={{ textAlign: "left" }}>
                                  <b style={{ marginRight: 10 }}>Tutar:</b>
                                </td>
                                <td style={{ textAlign: "right" }}>
                                  {FormatTLBasic(selectedRow.amount)}
                                </td>
                              </tr>
                            </table>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  ) : null}
                  <Row>
                    <Col>
                      <iframe
                        id="ifmcontentstoprint"
                        style={{ height: 0, width: 0, position: "absolute" }}
                      ></iframe>
                      <div className="text-end">
                        {selectedRow?.status == "ok" ? (
                          <button
                            type="button"
                            className="btn btn-danger me-2"
                            onClick={() => {
                              toggleVoid()
                            }}
                          >
                            İptal
                          </button>
                        ) : null}
                        <button
                          type="button"
                          className="btn btn-success"
                          onClick={() => {
                            var content =
                              document.getElementById("couponpreview")
                            // console.log(_logo)
                            // console.log(header_img_1)
                            // console.log(header_img_2)
                            if (content) {
                              var pri =
                                document.getElementById(
                                  "ifmcontentstoprint"
                                ).contentWindow
                              if (pri) {
                                pri.document.open()

                                pri.document.write(
                                  '<div style="width:98%;border:1px solid #000;text-align:center">' +
                                    content.innerHTML +
                                    '<p style="font-size:12px;color:#5b5b5b">' +
                                    selectedRow._id +
                                    "</p>" +
                                    "</div>" +
                                    "<style>label{font-weight:600;margin-bottom:0.25rem !important}</style>"
                                )
                                pri.document.close()
                                pri.focus()
                                pri.print()
                              }
                            }
                          }}
                        >
                          Yazdır
                        </button>
                      </div>
                    </Col>
                  </Row>
                </ModalBody>
              </Modal>

              <Modal isOpen={voidModal} toggle={toggleVoid}>
                <ModalHeader toggle={toggleVoid} tag="h4">
                  İptal
                </ModalHeader>
                <ModalBody>
                  <Form
                    onSubmit={e => {
                      e.preventDefault()
                      validation2.handleSubmit()
                      return false
                    }}
                  >
                    <Row form>
                      <Col className="col-12">
                        <div className="mb-3">
                          <Label className="form-label">Açıklama</Label>
                          <Input
                            name="description"
                            type="textarea"
                            validate={{
                              required: { value: true },
                            }}
                            onChange={validation2.handleChange}
                            onBlur={validation2.handleBlur}
                            value={validation2.values.description || ""}
                            invalid={
                              validation2.touched.description &&
                              validation2.errors.description
                                ? true
                                : false
                            }
                          />
                          {validation2.touched.description &&
                          validation2.errors.description ? (
                            <FormFeedback type="invalid">
                              {validation2.errors.description}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="text-end">
                          <button type="submit" className="btn btn-danger">
                            Kaydet
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </ModalBody>
              </Modal>

              {error && error ? (
                <SweetAlert
                  title={"Hata"}
                  warning
                  confirmBtnText={"Tamam"}
                  onConfirm={() => {
                    dispatch(onResetCoupon())
                  }}
                >
                  {JSON.stringify(error)}
                </SweetAlert>
              ) : null}
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(Dashboard))
