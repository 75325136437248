import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_COUPONS,
  GET_COUPONS_SERVERSIDE,
  GET_COUPONS_REPORT,
  ADD_COUPON,
  UPDATE_COUPON,
  DELETE_COUPON,
} from "./actionTypes"
import {
  getCouponsSuccess,
  getCouponsFail,
  getCouponsReportSuccess,
  getCouponsReportFail,
  deleteCouponSuccess,
  deleteCouponFail,
  updateCouponSuccess,
  updateCouponFail,
  addCouponSuccess,
  addCouponFail,
  getCouponsServerSideSuccess,
  getCouponsServerSideFail, 
} from "./actions"

//Include Both Helper File with needed methods
import {
  getCoupons, //API CONNECTION
  getCouponsServerSide,
  delCoupon,
  addCoupon,
  updateCoupon,
  getCouponReport
} from "../../helpers/fakebackend_helper"

function* fetchCoupons() {
  try {
    const response = yield call(getCoupons)
    yield put(getCouponsSuccess(response))
  } catch (error) {
    yield put(getCouponsFail(error))
  }
}

function* fetchCouponsServerSide({ payload: {page, limit, select, orderBy, ascDesc} }) {
  console.log("fetchCouponsServerSide",page, limit, select, orderBy, ascDesc)
  try {
    const response = yield call(getCouponsServerSide, page, limit, select, orderBy, ascDesc)
    yield put(getCouponsServerSideSuccess(response))
  } catch (error) {
    yield put(getCouponsServerSideFail(error))
  }
}

function* fetchCouponsReport() {
  try {
    const response = yield call(getCouponReport)
    yield put(getCouponsReportSuccess(response))
  } catch (error) {
    yield put(getCouponsReportFail(error))
  }
}

function* onDeleteCoupons({ payload: id }) {
  try {
    const response = yield call(delCoupon, id)
    yield put(deleteCouponSuccess(response))
  } catch (error) {
    yield put(deleteCouponFail(error))
  }
}

function* onAddCoupon({ payload: branch }) {
  try {
    const response = yield call(addCoupon, branch)
    yield put(addCouponSuccess(response))
  } catch (error) {
    yield put(addCouponFail(error))
  }
}

function* onUpdateCoupon({ payload: branch }) {
  try {
    const response = yield call(updateCoupon, branch)
    yield put(updateCouponSuccess(response))
  } catch (error) {
    yield put(updateCouponFail(error))
  }
}

export function* branchsSaga() {
  yield takeEvery(GET_COUPONS, fetchCoupons)
  yield takeEvery(GET_COUPONS_SERVERSIDE, fetchCouponsServerSide) 
  yield takeEvery(GET_COUPONS_REPORT, fetchCouponsReport)
  yield takeEvery(DELETE_COUPON, onDeleteCoupons)
  yield takeEvery(ADD_COUPON, onAddCoupon)
  yield takeEvery(UPDATE_COUPON, onUpdateCoupon)
}

export default branchsSaga
